@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
.ant-layout-sider .logo {
  height: 32px;
  margin: 16px;
  text-align: center;
  overflow: hidden;
}
.ant-layout-sider .logo .imgLogo {
  display: inline-block;
  height: 100%;
  margin-right: 10px;
}
.ant-layout-sider .logo .imgType {
  display: inline-block;
  height: 100%;
}
.ant-layout-sider-collapsed .logo .imgLogo {
  margin-right: 0;
}
.ant-layout-sider-collapsed .logo .imgType {
  display: none;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.userMenu .menuToggle {
  padding: 0 12px;
  display: inline-block;
  transition: all 0.3s;
  cursor: pointer;
}
.userMenu .menuToggle:hover {
  background-color: #e6fffe;
}
.userMenu .menuToggle > .avatar {
  margin-right: 8px;
  vertical-align: middle;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.header {
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  z-index: 1;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}
.trigger:hover {
  color: #00BCD4;
}
.right {
  float: right;
  height: 100%;
}

.footer {
  text-align: center;
}

.main-layout_app__2pUzk {
  min-height: 100vh;
}

.main-layout_content__3vICW {
  padding: 24px;
  min-height: 360px;
}
.simple-layout_app__OHLp_ {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f0f2f5;
}
.simple-layout_content__2YXIP {
  padding: 32px 0;
  flex: 1 1;
}

.simple-layout_top__2zTOW {
  text-align: center;
}

.simple-layout_header__l4tp9 {
  height: 44px;
  line-height: 44px;
  box-shadow: none !important;
}

.simple-layout_header__l4tp9 a {
  text-decoration: none;
}

.simple-layout_logo___y3_k {
  height: 44px;
  vertical-align: top;
  margin-right: 16px;
}

.simple-layout_desc__BePfk {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 12px;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .simple-layout_app__OHLp_ .simple-layout_content__2YXIP {
    padding: 112px 0 24px 0;
  }
}

.login-page_container__3B-D4 {
  width: 368px;
  margin: 0 auto;
}

h2 {
  color: rgba(0, 0, 0, .65);
  margin-bottom: 24px;
}

.login-page_alert__3zaIj {
  margin-bottom: 10px;
}

.login-page_adminOnly__23k46 {
  text-align: center;
}

.login-page_adminOnly__23k46>div {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  grid-gap: 8px;
  gap: 8px;
}

.login-page_adminOnly__23k46>div img {
  max-width: 100%;
}
.importusersmodal_validations__dFBxW .ant-table {
  overflow: auto;
  margin-bottom: 32px;
  max-height: 200px;
}

.importusersmodal_steps__32EX5 {
  margin-bottom: 24px;
}

.importusersmodal_validations__dFBxW th, .importusersmodal_validations__dFBxW td {
  white-space: nowrap;
}

.importusersmodal_validations__dFBxW h2 {
  margin-bottom: 4px;
}

.importusersmodal_validations__dFBxW div:first-of-type h2 {
  color: #a4000f;
}

.importusersmodal_validations__dFBxW div:nth-of-type(2) h2 {
  color: #d7b600;
}

.importusersmodal_validations__dFBxW div:nth-of-type(3) h2 {
  color: #119600;
}

.importusersmodal_validations__dFBxW .anticon-exclamation-circle {
  color: #d7b600;
}

.importusersmodal_validations__dFBxW .anticon-close-circle {
  color: #a4000f;
}

.importusersmodal_validations__dFBxW .anticon-check-circle {
  color: #119600;
}

.importusersmodal_summary__6NPrt {
  color: inherit;
}

.importusersmodal_summary__6NPrt {
  text-align: center;
}

.importusersmodal_summary__6NPrt .anticon-check-circle {
  color: #119600;
  font-size: 4em;
  margin: 12px;
}

.importusersmodal_summary__6NPrt .anticon-close-circle {
  color: #a4000f;
  font-size: 4em;
  margin: 12px;
}
body .tbl-action {
  padding: 0;
}

body .tbl-action>i {
  font-size: 1.2em !important;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
}

body .tbl-action:hover>i {
  color: #ED5244;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
  gap: 8px;
}

.nameAndEmail {
  flex: 1 1;
  line-height: 120%;
}

.nameAndEmail>div {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.nameAndEmail>div:last-child {
  font-weight: normal;
  color: #999;
}

.striped-rows td,
.striped-rows th,
.striped-rows td:first-child {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.filter-row .ant-form-item {
  margin-bottom: 5px !important;
}
.filter-row .del-icon {
  margin-top: 12.5px;
  font-weight: bold;
  cursor: pointer;
}

body .top-buttons {
  margin-bottom: 10px;
  text-align: right;
}

.content-internal {
  margin-bottom: 20px;
}

body .top-buttons {
  margin-bottom: 10px;
  text-align: right;
}

/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body .formRight {
  text-align: right;
}

body .top-bar {
  margin-bottom: 20px;
}

body .add-course-btn {
  text-align: right;
  margin-bottom: 7px;
}

body .drag-item {
  border: 1px solid #ddd;
  border-radius: 8px;
}

body .ext-drag-item {
  min-height: 38px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  position: relative;
}

body .ext-drag-item:not(:first-child) {
  margin-top: 10px;
}

body .drag-item .badge {
  margin: 8px;
  border-radius: 10px;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: white;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  background-color: #00BCD4;
  padding: 0 6px;
}

body .drag-item .del-icon {
  top: 12px;
  position: absolute;
  right: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #00BCD4;
  cursor: pointer;
}

.heading-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.path-section {
  margin-bottom: 40px;
}
.new-course-modal_uploader__Db4Xk {
  width: 100%;
}

.new-course-modal_uploader__Db4Xk .ant-upload {
  width: 100%;
  height: 94px;
}

.new-course-modal_uploader__Db4Xk .ant-upload img {
  height: 78px;
}
body .top-buttons {
  margin-bottom: 10px;
  text-align: right;
}
body .course-image {
  object-fit: cover;
  height:200px;
}
body .course-placeholder {
  height:200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
}
body .course-placeholder > i {
  font-size: 3em;
}

body .card-actions {
  position: absolute;
  bottom: 4px;
  right: 12px;
}
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body .tbl_report {
  margin-bottom: 20px;
}
body .tbl_report .ant-table:not(.ant-table-empty) .ant-table-body {
  height: 240px;
}
body .tbl_report .ant-table-placeholder {
  height: 240px;
}
body .formRight {
  text-align: right;
}
body .top-bar {
  margin-bottom: 20px;
}
body .add-course-btn {
  text-align: right;
  margin-bottom: 7px;
}
body .drag-item {
  border: 1px solid #ddd;
  border-radius: 8px;
}
body .drag-item .badge {
  margin: 8px;
  border-radius: 10px;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: white;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  background-color: #00BCD4;
  padding: 0 6px;
}
body .drag-item .del-icon {
  top: 12px;
  position: absolute;
  right: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #00BCD4;
  cursor: pointer;
}

tr.disabled {
  color: #bbb;
}
.course-scoretable_page__d7aBz {
  min-height: 100vh;
  font-family:"Open Sans",sans-serif;
}
.course-scoretable_header__1pIun {
  padding: 20px;
  border-bottom: 1px solid #e7e7e7;
  height: auto;
}

.course-scoretable_header__1pIun h1 {
  font-size: 36px;
  margin-bottom: 0;
}

.course-scoretable_content__2Z_YE {
  background-color: white;
  padding-top: 30px;
}

.course-scoretable_logo__3EaxQ {
    position: absolute;
    top: 20px;
    right: 20px;
}

.course-scoretable_logo__3EaxQ img {
  height: 40px;
}

.course-scoretable_footer__3Eq_M {
  background-color: white;
}


.course-scoretable_table__IyYjG {

}

.course-scoretable_tableItem__32B8M {
  position: relative;
  border-bottom: 1px solid #eee;
  height: 90px;
  margin-bottom: 10px;
  font-family:"Open Sans",sans-serif;
}

.course-scoretable_tableItem__32B8M .course-scoretable_pic__2i-T8 {
  float: left;
  position: relative;
}

.course-scoretable_tableItem__32B8M .course-scoretable_pic__2i-T8 img {
  width: 80px;
  height: 80px;
  border-radius: 40px;
}

.course-scoretable_tableItem__32B8M .course-scoretable_pic__2i-T8 .course-scoretable_position__3KVdb {
  position: absolute;
  bottom: -5px;
  left: 50%;
  background-color: #00bcd4;
  color: white;
  padding: 3px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 13px;
  font-size: 12px;
  transform: translateX(-50%);
}

.course-scoretable_tableItem__32B8M .course-scoretable_points__3WZfL {
  float: right;
  color: #00bcd4;
  font-size: 2rem;
  line-height: 70px;
}

.course-scoretable_tableItem__32B8M .course-scoretable_itemBody__2V2xc {
  margin-left: 90px;
}

.course-scoretable_tableItem__32B8M .course-scoretable_itemBody__2V2xc h2 {
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.course-scoretable_tableItem__32B8M .course-scoretable_itemBody__2V2xc p {
  font-size: 18px;
  color: #aaa;
  margin: 0 0 10px;
}
.filters-sidebar_filters__3E6VY {
}

.filters-sidebar_filters__3E6VY label {
  color: #666;
  font-weight: lighter;
}

.filters-sidebar_filters__3E6VY .ant-collapse-header {
  padding-left: 16px !important;
}

.filters-sidebar_filters__3E6VY .ant-collapse-header .arrow {
  left: 0px !important;
}

.filters-sidebar_filters__3E6VY .ant-collapse-content-box {
  padding: 0px !important;
  margin-right: 2px;
}

.color-picker_color__3yJzg {
  width: 36px;
  height: 14px;
  border-radius: 2px;
}

.color-picker_swatch__PBMXh {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  cursor: pointer;
}

.color-picker_disabled__qkM21 {
  cursor: not-allowed;
  opacity: 0.5;
}

.color-picker_popover__3js4k {
  color:black;
}

.color-picker_popover__3js4k .ant-popover-arrow {
  display:none;
}

.color-picker_popover__3js4k .ant-popover-inner {
  background-color: transparent;
  box-shadow: none;
}

.color-picker_cover__6nbiY {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
label {
  font-weight: 500;
  margin-bottom: 5px;
  display:block;
}

.ant-list-vertical .ant-list-item-content {
  margin-bottom: 0px;
}

.ant-select {
  width: 100%;
}

.props-editor_hiddenOpts__3Vuq0 {
  background-color: transparent;
  margin-top: 20px;
  
}

.props-editor_hiddenOpts__3Vuq0 .ant-collapse-item {
  border-bottom: none !important;
}

.props-editor_hiddenOpts__3Vuq0 .ant-collapse-header {
  padding-left: 28px !important;
  color: #999 !important;
}

.props-editor_hiddenOpts__3Vuq0 .ant-collapse-header i {
  left: 4px !important;
}

.props-editor_hiddenOpts__3Vuq0 .ant-collapse-content-box {
  padding: 0;
}
.common_templateContainer__2iOb6 {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 46px;
  padding-bottom: 64.5px;
  padding-left: 15px;
  padding-right: 15px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.common_templateTitle__3tOXg {
  font-size: 2.2vw;
  font-weight: bold;
  text-align: center;
  margin: 1.5vw;
  background-color: transparent;
  margin-top: 4px;
}

.common_templateBody__3wnAD {
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: center;
  align-self: stretch;
}

.common_templateBody__3wnAD > :first-child {
  margin-top: auto;
}

.common_templateBody__3wnAD > :last-child {
  margin-bottom: auto;
}

.common_templateText__2VEaP {
  margin: 1vw;
  font-size: 1.8vw;
  line-height: 2vw;
  text-align: center;
}

.common_templateSubText__25Hc2 {
  margin: 1vw;
  font-size: 1.5vw;
  line-height: 1.8vw;
  text-align: center;
}

.common_templateImage__huFWt {
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}

.common_miniature__1l-5T {
  padding: 10px;
}

.common_miniature__1l-5T .common_minIndex__3Sanx {
  font-size: 0.7rem;
  font-weight: bold;
  border-radius: 15px;
  border: 2px solid white;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  padding: 2px;
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  line-height: 21px;
  background-color: white;
}

.common_miniature__1l-5T .common_minIcon__G6j8y {
  width: 30px;
  height: 30px;
}

.common_miniature__1l-5T .common_minTitle__2cFvR {
  font-size: 0.8rem;
}
.section_index__2GFB2 {
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 6vw;
  height: 6vw;
  border-radius: 3vw;
  padding: 0.5vw;
  margin-bottom: 1.5vw;
  overflow: hidden;
  font-size: 2.5vw;
  font-weight: bold;
  text-align: center;
  line-height: 4.5vw;
}

.section_icon__lZgR_ {
  width: 15vw;
  height: 15vw;
}

.section_text__2PQ4w {
  color: white;
  font-weight: bold;
  font-size: 2vw;
  text-align: center;
  margin-top: 1.5vw;
}




.theme-editor_tabColors__3Rw0y .ant-col, .theme-editor_tabOther__18Dk7 .ant-col {
  display: flex;
  align-items: center;
  padding: 0 4px;
}

.theme-editor_tabImages__16gM8 .ant-col {
  display: flex;
  flex-direction: column;
  padding: 0 4px;
}

.theme-editor_tabImagesInner__2DnoB {
  display: flex;
  align-items: center;
  padding: 0 4px;
}

.theme-editor_label__1COZ- {
  display: inline-block;
  margin: 0;
  flex: 1 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.theme-editor_switch__19ofI {
  margin: 8px;
}

.theme-editor_tabImages__16gM8 .theme-editor_image__2LPx1 {
  height: 0;
  border: 1px solid #ddd;
  /* margin: 8px; */
  padding: 8px;
  padding-bottom: calc(100% / 0.5625);
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.theme-editor_tabImages__16gM8 .theme-editor_image__2LPx1 > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.theme-editor_tabImages__16gM8 .theme-editor_image__2LPx1 img {
  object-fit: contain;
  display: flex;
  width: 100%;
}

.theme-editor_deleteIcon__1WAZV {
  background-color: rgba(0,0,0,0.6);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color:white;
  padding: 4px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor:pointer;
  position: absolute;
  top: 43px;
  right: 8px;
  z-index:2;
}

.theme-editor_tabImages__16gM8 .theme-editor_image__2LPx1 .ant-empty {
  font-size: 1.4em;
  color: #bbb;
  display: flex;
  flex-direction: column;
}

.theme-editor_tabImages__16gM8 .theme-editor_image__2LPx1 .ant-empty .anticon {
  font-size: 5em;
}

.theme-editor_tabOther__18Dk7 input, .theme-editor_tabOther__18Dk7 .ant-select {
  width: auto;
  max-width: 130px;
}

.theme-editor_tabOther__18Dk7 .ant-radio-group, .theme-editor_tabOther__18Dk7 .theme-editor_inputs__330n3 {
  flex: 1 1;
}

.theme-editor_disabled__1tPIT {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.section-subjects_container__T2a0E {
  background-color: #3498db;
  padding-top: 4.6vw;
  padding-bottom: 6.45vw;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  flex:1 1;
}

.section-subjects_icon__23Hpw {
  width: 15vw;
  height: 15vw;
}

.section-subjects_title__1SBNB {
  color: white;
  font-weight: bold;
  font-size: 2vw;
  text-align: center;
  margin-top: 1.5vw;
  margin-bottom: 1.5vw;
}

.section-subjects_text__24Lj6 {
  color: white;
  font-size: 1.7vw;
  text-align: center;
}

.section-subjects_separator__3-RUk {
  width: 6vw;
  margin: 2vw;
  border: 0;
  border-top: 0.2vw solid white;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}



.concept_link__2c5HO {
  text-decoration: underline;
}
.single-choice_body__y8Wzn .ant-list {
  width: 100%;
  font-size: 1.3vw;
  border-radius: 1vw;
  border: 0.1vw solid #d9d9d9;
}

.single-choice_body__y8Wzn .ant-list .ant-list-item:first-child {
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
}

.single-choice_body__y8Wzn .ant-list .ant-list-item:last-child {
  border-bottom-left-radius: 1vw;
  border-bottom-right-radius: 1vw;
}

.single-choice_body__y8Wzn .ant-list-item {
  border-bottom: 0.1vw solid #d9d9d9;
  padding-left: 1vw;
  padding-right: 1vw;
  display: flex;
  flex-direction: row;
}

.single-choice_body__y8Wzn .ant-list-item-content {
  align-items: center;
}

.single-choice_icon__1nFoW {
  margin-right: 1vw;
  width: 1.7vw;
  height: 1.7vw;
  border: 0.1vw solid #d9d9d9;
  border-radius: 50%;
}

.single-choice_selected__1XzzI {
  color: white;
}

.single-choice_selected__1XzzI .single-choice_icon__1nFoW {
  border: 0.1vw solid white;
}

.single-choice_selected__1XzzI .single-choice_icon__1nFoW::after {
  content: '';
  display: block;
  background-color: var(--color);
  width: 1vw;
  height: 1vw;
  border-radius: 0.5vw;
  margin: 0.25vw;
  border: none;
}

.single-choice_optionText__2p-n9 {
  flex: 1 1;
}



.multiple-choice_body__3wJbo .ant-list {
  width: 100%;
  font-size: 1.3vw;
  border-radius: 1vw;
  border: 0.1vw solid #d9d9d9;
}

.multiple-choice_body__3wJbo .ant-list .ant-list-item:first-child {
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
}

.multiple-choice_body__3wJbo .ant-list .ant-list-item:last-child {
  border-bottom-left-radius: 1vw;
  border-bottom-right-radius: 1vw;
}

.multiple-choice_body__3wJbo .ant-list-item {
  border-bottom: 0.1vw solid #d9d9d9;
  padding-left: 1vw;
  padding-right: 1vw;
  display: flex;
  flex-direction: row;
}

.multiple-choice_body__3wJbo .ant-list-item-content {
  align-items: center;
}

.multiple-choice_icon__1xPSu {
  margin-right: 1vw;
  width: 1.7vw;
  height: 1.7vw;
  background-color: #d9d9d9;
  font-size: 1vw;
  line-height: 1.9vw;
}

.multiple-choice_icon__1xPSu svg {
  opacity: 0;
}

.multiple-choice_selected__3aMs9 {
  color: white;
}

.multiple-choice_selected__3aMs9 .multiple-choice_icon__1xPSu {
  background-color: white; 
}

.multiple-choice_selected__3aMs9 .multiple-choice_icon__1xPSu svg {
  opacity: 1;
}

.multiple-choice_optionText__rQ-nv {
  flex: 1 1;
}




.tip_container__rRW1f {
  background-color: #f4a056;
}

.tip_title__RvxBO {
  color: white;
}

.tip_image__3aH0v {
  width: 10vw;
  height: 10vw;
  margin-top: 2vw;
}

.tip_text__2dnwf {
  color: white;
}
.challenge_container__5DjLp {
  background-color: #222335;
}

.challenge_title__3pENT {
  color: white;
}

.challenge_text__3iu67 {
  color: white;
}

.challenge_link__UhNJ0 {
  text-decoration: underline;
}
.drag-and-drop_image__1ba2u {
  width: 15vw;
  height: 15vw;
}

.drag-and-drop_optionsContainer__qlmcY {
  margin-top: 2vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
}

.drag-and-drop_option__3vIth {
  color: white;
  border-radius: 1.2vw;
  padding: 0.5vw;
  text-align: center;
  overflow: hidden;
  font-size: 1.2vw;
  margin: 0.5vw;
  z-index: 2;
  height: auto;
}

.order-phrase_option__1oSi4 {
  color: white;
  border-radius: 1vw;
  padding: 1vw;
  text-align: center;
  overflow: hidden;
  font-size: 1.2vw;
  z-index: 2;
  height: auto;
  display:block;
  width: 100%;
  margin-top: 0.5vw !important;
  margin-bottom: 0.5vw !important;
  box-shadow: 0px 3px 1px 0px #d9d9d9;
  white-space: normal;
  text-align: left;
}
.type_image__hfFNA {
  width: 12vw;
  height: 12vw;
  margin: 1vw;
}

.type_text__1ID0O {
  margin: 1vw 3vw;
}

.type_input__A9ccd {
  flex: 1 1;
  align-self: stretch;
  background-color: white;
  border-color: #D6D6D6;
  border-width: 0.1vw;
  border-radius: 1vw;
  padding: 1vw;
  font-size: 1.4vw;
  vertical-align: top;
  min-height: 5vw;
}

.type_input__A9ccd:hover {
  border-color: #D6D6D6;
}
.complete-phrase_phraseContainer__2XIta {
  margin-top: 2.5vw;
  margin-bottom: 2.5vw;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display:flex;
}

.complete-phrase_helpText__3icPD {
  text-align: center;
  color: #949494;
  margin: 2vw;
}

.complete-phrase_phraseWord__jXUnK {
  font-size: 1.6vw;
  margin-left: 0.2vw;
  margin-right: 0.2vw;
  margin-top: 0.5vw;
  margin-bottom: 0.5vw;
  display:flex;
}

.complete-phrase_phraseBreakLine__1aB5u {
  flex-basis: 100%;
  width: 0;
  height: 0;
  overflow: hidden;
  display:flex;
}

.complete-phrase_optionsContainer__3276k {
  margin-top: 2vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
}

.complete-phrase_option__-2tg6 {
  border-radius: 1.2vw;
  padding: 0.5vw;
  text-align: center;
  overflow: hidden;
  font-size: 1.2vw;
  margin: 0.5vw;
  z-index: 10;
  height: auto;
}
.sound_button__1ZCX5 {
  width: 8vw !important;
  height: 8vw !important;
  align-self: center;
  font-size: 3vw !important;
}
.sort_image__3noyX {
  width: 15vw;
  height: 15vw;
}

.sort_optionsContainer__iHhSH {
  margin-top: 2vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
}

.sort_option__1xqWy {
  color: white;
  border-radius: 1.2vw;
  padding: 0.5vw;
  text-align: center;
  overflow: hidden;
  font-size: 1.2vw;
  margin: 0.5vw;
  z-index: 2;
  height: auto;
}

.sort_podium__3bdWD {
  position:relative;
  width:100%;
}

.sort_podium__3bdWD > img {
  width: 100%;
}

.sort_spacesContainer__CEx9i {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vw;
  align-items: flex-end;
}

.sort_space__meAat {
  background-color: #d6d6d6;
  border-width: 0.3vw;
  border-color: #ccc;
  border-radius: 50%;
  padding: 2vw;
  align-items: center;
  width: 25%;
  height:0;
  padding-bottom: 20%;
  display: flex;
  justify-content: center;
  margin-bottom: 19vw;
}

.sort_space__meAat img {
  margin-top: 100%;
  max-width: 100%;
}

.sort_space__meAat:first-child {
  margin-bottom: 9.5vw;
}

.sort_space__meAat:last-child {
  margin-bottom: 8vw;
}

.sort_helpText__en02p {
  text-align: center;
  color: #949494;
  margin: 2vw;
}
.video_body__23xta {
  margin-right: -15px;
  margin-left: -15px;
}
.record_timer__3Tgf_ {
  font-size: 5vw;
  margin: 2vw;
  text-align: center;
}

.image-choice_radioContainer__1InHk {
  display: flex;
  flex: 1 1;
}

.image-choice_radio__Fiu5m {
  display: flex;
  flex:1 1;
  flex-direction: column;
  margin: 1vw;
  align-items: center;
  justify-content: stretch;
}

.image-choice_radio__Fiu5m img {
  width: 100%;
  flex:1 1;
}

.image-choice_icon__xIRNi {
  margin-right: 1vw;
  width: 1.7vw;
  height: 1.7vw;
  border: 0.1vw solid #d9d9d9;
  border-radius: 50%;
}

.image-choice_selected__hjO6K {
  color: white;
}

.image-choice_selected__hjO6K .image-choice_icon__xIRNi {
  border: 0.1vw solid white;
}

.image-choice_selected__hjO6K .image-choice_icon__xIRNi::after {
  content: '';
  display: block;
  background-color: var(--color);
  width: 1vw;
  height: 1vw;
  border-radius: 0.5vw;
  margin: 0.25vw;
  border: none;
}



.whatsapp_link__BUKum {
  text-decoration: underline;
}

.whatsapp_container__NjA7j {
  background-image: url(/static/media/wp-bg.d8cd66c3.jpg);
  align-items: flex-end;
}

.whatsapp_message__28O3F {
  background-color: #DCF7C5;
  padding: 8px;
  max-width: 300px;
  border-radius: 8px;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.whatsapp_file__rSK9i {
  background-color: rgba(118, 118, 128, 0.12);
  padding: 4px 12px;
  border-radius: 4px;
}

.whatsapp_image__VosCA img, .whatsapp_image__VosCA video {
  width: 100%;
  margin-bottom: 8px;
}
.whatsapp-list_body__1EtKa .ant-list {
  width: 100%;
  font-size: 1.3vw;
  border-radius: 1vw;
  border: 0.1vw solid #d9d9d9;
}

.whatsapp-list_body__1EtKa .ant-list .ant-list-item:first-child {
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
}

.whatsapp-list_body__1EtKa .ant-list .ant-list-item:last-child {
  border-bottom-left-radius: 1vw;
  border-bottom-right-radius: 1vw;
}

.whatsapp-list_body__1EtKa .ant-list-item {
  border-bottom: 0.1vw solid #d9d9d9;
  padding-left: 1vw;
  padding-right: 1vw;
  display: flex;
  flex-direction: row;
}

.whatsapp-list_body__1EtKa .ant-list-item-content {
  align-items: center;
}

.whatsapp-list_icon__2nKa6 {
  margin-right: 1vw;
  width: 1.7vw;
  height: 1.7vw;
  border: 0.1vw solid #d9d9d9;
  border-radius: 50%;
}

.whatsapp-list_selected___Ry2S {
  color: white;
}

.whatsapp-list_selected___Ry2S .whatsapp-list_icon__2nKa6 {
  border: 0.1vw solid white;
}

.whatsapp-list_selected___Ry2S .whatsapp-list_icon__2nKa6::after {
  content: '';
  display: block;
  background-color: var(--color);
  width: 1vw;
  height: 1vw;
  border-radius: 0.5vw;
  margin: 0.25vw;
  border: none;
}

.whatsapp-list_optionText__2OdOT {
  flex: 1 1;
}

.whatsapp-list_container__1Hwus {
  background-image: url(/static/media/wp-bg.d8cd66c3.jpg);
}

.infographic_link__2Fn65 {
  text-decoration: underline;
}

.infographic_container__-_lkG {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
}

.options_editableRow__eC8p9 .ant-form-explain {
  display:none;
}

.options_editableRow__eC8p9 .ant-btn {
  padding: 0;
}

.miniature_miniature__1_c_2 {
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
}

.miniature_miniature__1_c_2 .ant-card {
  border-radius: 4px; 
}

.miniature_miniature__1_c_2 .ant-card-body {
  padding: 0px;
  text-align: center;
}

.miniature_selected__2cHMe {
  border: 2px solid #ED5244;
}

/* .separator {
  height: 4px;
}

.separator:not(.muted) {
  width: 100%;
  cursor: pointer;
}

.separator:not(.muted):hover {
  background-color: #ddd;
} */

.strip_newButton__2KFnZ .ant-card {
  border-style: dashed;
  border-color: #ddd;
}

.strip_newButton__2KFnZ .ant-card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.strip_newButton__2KFnZ:hover .ant-card {
  color: #ED5244;
  border-color: #ED5244;
}

.screen-preview_prev__385SW {
  font-family: 'Roboto', sans-serif;
}
.course-design_header__3abPM {
  width: 100%;
  padding: 22px 7px;
  background-color: transparent;
}
.new-content-modal_card__2F438 {
  
}

.new-content-modal_card__2F438 svg {
  width: 100%;
  height: auto;
  fill: #aaa;
  padding: 20px;
  stroke: #aaa;
}

.new-content-modal_card__2F438:hover svg {
  fill: #ED5244;
}

.new-content-modal_card__2F438 .ant-typography-ellipsis{
  min-height: 42px;
}

.new-content-modal_searchBox__t_e0U {
  margin-bottom: 10px;
}
body .top-buttons {
  margin-bottom: 10px;
  text-align: right;
}

body .top-buttons {
  margin-bottom: 10px;
  text-align: right;
}

.filters-sidebar_filters__3RRyI {
}

.filters-sidebar_filters__3RRyI label {
  color: #666;
  font-weight: lighter;
}

.filters-sidebar_filters__3RRyI .ant-collapse-header {
  padding-left: 16px !important;
}

.filters-sidebar_filters__3RRyI .ant-collapse-header .arrow {
  left: 0px !important;
}

.filters-sidebar_filters__3RRyI .ant-collapse-content-box {
  padding: 0px !important;
  margin-right: 2px;
}

.ant-select-dropdown-menu-item-group-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.filters-sidebar_switchCont__vcRBY {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.ant-descriptions-item > span {
  max-width: 100%;
  overflow-wrap: break-word;
}

.userBadge img {
  width: 100%;
  height: 90px;
  object-fit: contain;
  margin-bottom: 8px;
}

.userBadge-off img {
  filter: grayscale(1) opacity(0.4);
}

.userBadge .ub-title {
  font-weight: bold;
}

.userBadge .ub-desc {
  color: #aaa;
}

.ant-calendar-picker, .ant-input-number {
  width: 100% !important;
}
.course-selection_topButtons__23zoz {
  margin-bottom: 20px;
  text-align: right;
}

.course-selection_courseImage__3b5sI {
  object-fit: cover;
  height:200px;
}

.course-selection_coursePlaceholder__3eqPi {
  height:200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
}

.course-selection_coursePlaceholder__3eqPi > i {
  font-size: 3em;
}

body .top-buttons {
  margin-bottom: 10px;
  text-align: right;
}
body .course-image {
  object-fit: cover;
  height:200px;
}
body .course-placeholder {
  height:200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
}
body .course-placeholder > i {
  font-size: 3em;
}

body .card-actions {
  position: absolute;
  bottom: 4px;
  right: 12px;
}
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body .tbl_report {
  margin-bottom: 20px;
}
body .tbl_report .ant-table:not(.ant-table-empty) .ant-table-body {
  height: 240px;
}
body .tbl_report .ant-table-placeholder {
  height: 240px;
}
body .formRight {
  text-align: right;
}
body .top-bar {
  margin-bottom: 20px;
}
body .add-course-btn {
  text-align: right;
  margin-bottom: 7px;
}
body .drag-item {
  border: 1px solid #ddd;
  border-radius: 8px;
}
body .drag-item .badge {
  margin: 8px;
  border-radius: 10px;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: white;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  background-color: #00BCD4;
  padding: 0 6px;
}
body .drag-item .del-icon {
  top: 12px;
  position: absolute;
  right: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #00BCD4;
  cursor: pointer;
}

tr.disabled {
  color: #bbb;
}
.importusersmodal_validations__3V0fc .ant-table {
  overflow: auto;
  margin-bottom: 32px;
  max-height: 200px;
}

.importusersmodal_steps__JNj1S {
  margin-bottom: 24px;
}

.importusersmodal_validations__3V0fc th, .importusersmodal_validations__3V0fc td {
  white-space: nowrap;
}

.importusersmodal_validations__3V0fc h2 {
  margin-bottom: 4px;
}

.importusersmodal_validations__3V0fc div:first-of-type h2 {
  color: #a4000f;
}

.importusersmodal_validations__3V0fc div:nth-of-type(2) h2 {
  color: #d7b600;
}

.importusersmodal_validations__3V0fc div:nth-of-type(3) h2 {
  color: #119600;
}

.importusersmodal_validations__3V0fc .anticon-exclamation-circle {
  color: #d7b600;
}

.importusersmodal_validations__3V0fc .anticon-close-circle {
  color: #a4000f;
}

.importusersmodal_validations__3V0fc .anticon-check-circle {
  color: #119600;
}

.importusersmodal_summary__1PCRI {
  color: inherit;
}

.importusersmodal_summary__1PCRI {
  text-align: center;
}

.importusersmodal_summary__1PCRI .anticon-check-circle {
  color: #119600;
  font-size: 4em;
  margin: 12px;
}

.importusersmodal_summary__1PCRI .anticon-close-circle {
  color: #a4000f;
  font-size: 4em;
  margin: 12px;
}
body .top-buttons {
  margin-bottom: 10px;
  text-align: right;
}

.dashboardpage_top-buttons__1vA-T {
  margin-bottom: 10px;
}

.dashboardpage_header__pOxzi {
}

.dashboardpage_header__pOxzi h1 {
  font-size: 1.7em;
  float: left;
  margin-top: 12px;
  margin-bottom: 0;
}

.dashboardpage_topLine__1FaBD {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.dashboardpage_topLine__1FaBD .ant-calendar-picker {
  width: 180px !important;
}

.dashboardpage_topLine__1FaBD .ant-calendar-picker .ant-calendar-picker-input {
  background-color: transparent;
  background-image: none;
  border: none;
  padding: 0;
  margin: 0;
  height: auto;
}

.dashboardpage_filter__2UC3C .ant-calendar-picker-clear {
  right: -2px;
}

.dashboardpage_filter__2UC3C .ant-input:focus, .dashboardpage_filter__2UC3C .ant-select-focused .ant-select-selection, .dashboardpage_filter__2UC3C .ant-calendar-picker:focus .ant-input {
  outline: none;
  box-shadow: none;
}

.dashboardpage_topLine__1FaBD .ant-calendar-picker .ant-calendar-picker-input input {
  height: auto;
  font-size: 0.8em;
}

.dashboardpage_collapse__2w36P {
  background-color: transparent;
  border: none;
}

.dashboardpage_collapse__2w36P .ant-collapse-item {
  border-bottom: none;
}

.dashboardpage_collapse__2w36P .ant-collapse-header {
  padding-right: 32px !important;
}

.dashboardpage_filter__2UC3C {
  border: 1.5px solid #ccc;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 0.9em;
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.dashboardpage_filter__2UC3C .ant-select {
  flex:1 1;
}

.dashboardpage_filter__2UC3C .ant-select-selection__rendered {
  line-height: 22px;
}

.dashboardpage_filter__2UC3C .ant-select-selection__rendered ul {
  height: 1px;
  position: absolute;
}

.dashboardpage_filter__2UC3C .ant-select-selection__choice {
  margin: 0 !important;
  background-color: transparent;
  border: none;
}

.dashboardpage_filter__2UC3C .ant-select-selection {
  background-color: transparent;
  border: none;
  height: auto;
  font-size: 0.9em;
  min-height: auto;
}

.dashboardpage_filterTitle__3npy3 {
  font-weight: bold;
}

.dashboardpage_moreFiltersLabel__3oozd {
  font-weight: bold;
  font-size: 0.9em;
  margin-left: 20px;
}

.dashboardpage_header__pOxzi .ant-collapse-content-box {
  padding-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.dashboardpage_header__pOxzi .ant-collapse-content-box .dashboardpage_filter__2UC3C {
  width: 200px;
}

.dashboardpage_dd__3GPzc {
  min-width: 200px !important;
}

.dashboardpage_indicator__1B1cy {
  margin-top: 20px;
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  min-height: 207px;
  box-shadow: 10px 10px 18px 2px rgba(235,235,235,1);
}

.dashboardpage_indicator__1B1cy h2 {
  font-size: 1em;
  margin: 0;
}

.dashboardpage_indicator__1B1cy p {
  color: rgba(0, 21, 41, 0.5);
  font-size: 0.8em;
}

.dashboardpage_indicatorBody__1r3YX {
  padding: 0 32px;
}
.indicator_title__1cRE4 {
  color: rgba(0, 21, 41);
  font-weight: bold;
  font-size: 2.5em;
}

.indicator_subtitle__2j4TD {
  color: rgb(120, 137, 153);
  font-size: 0.8em;
}

.indicator_number__3gQ8f {
  font-weight: bold;
  font-size: 3em;
  color: rgba(0, 21, 41);
  margin: 32px 0;
  text-align: center;
}
body .top-buttons {
  margin-bottom: 10px;
  text-align: right;
}
body .course-image {
  object-fit: cover;
  height:200px;
}
body .course-placeholder {
  height:200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
}
body .course-placeholder > i {
  font-size: 3em;
}

body .card-actions {
  position: absolute;
  bottom: 4px;
  right: 12px;
}
.instructors-modal_uploader__19A0h {
  width: 100%;
}

.instructors-modal_uploader__19A0h .ant-upload {
  width: 100%;
  height: 94px;
}

.instructors-modal_uploader__19A0h .ant-upload img {
  height: 78px;
}
.validation-page_container__kPVI3 {
  margin: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.validation-page_container__kPVI3 > img {
  height: 40px;
  margin-top: 70px;
}

.validation-page_innerContainer__2mvBX {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.validation-page_innerContainer__2mvBX > h1 {
  margin-bottom: 0;
}

.validation-page_innerContainer__2mvBX a {
  color: black;
}

.validation-page_badge__87FQa {
  max-width: 150px;
  margin-bottom: 36px;
}

.validation-page_red__d4VeQ {
  color: #e23636;
}

body .top-buttons {
  margin-bottom: 10px;
  text-align: right;
}
body .course-image {
  object-fit: cover;
  height:200px;
}
body .course-placeholder {
  height:200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
}
body .course-placeholder > i {
  font-size: 3em;
}

body .card-actions {
  position: absolute;
  bottom: 4px;
  right: 12px;
}
.communicationdetail_file__I7EbF {
  max-width: 300px;
}

.communicationdetail_file__I7EbF img,
.communicationdetail_file__I7EbF video {
  max-width: 100%;
}

body .tbl-action {
  padding: 0;
}

body .tbl-action>i {
  font-size: 1.2em !important;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
}

body .tbl-action:hover>i {
  color: #ED5244;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
  gap: 8px;
}

.nameAndEmail {
  flex: 1 1;
  line-height: 120%;
}

.nameAndEmail>div {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.nameAndEmail>div:last-child {
  font-weight: normal;
  color: #999;
}

.striped-rows td,
.striped-rows th,
.striped-rows td:first-child {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.preregistrationdetailpage_buttons__19xhp {
  margin-top: 40px;
  text-align: center;

  > :first-child {
    margin-right: 10px;
  }
}

.preregistrationdetailpage_desc__14l5q {
  .ant-descriptions-item-content {
    width: 90%;
  }
}

.preregistrationdetailpage_desc1__8ADm_ {
  vertical-align: top;
}

body .top-buttons {
  margin-bottom: 10px;
  text-align: right;
}

body {
  --color-primary: #ED5244;
  --color-secondary: #00BCD4;
}

.content-internal {
  padding: 24px;
  background-color: white;
}

.first-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  gap: 16px;
}

.aspect-ratio-9-16> :first-child {
  width: 100%;
}

.aspect-ratio-9-16>img {
  height: auto;
}

.aspect-ratio-9-16 {
  position: relative;
}

.aspect-ratio-9-16::before {
  content: "";
  display: block;
  padding-bottom: calc(100% / 0.5625);
  /* 0.5625 = 9/16 */
}

.aspect-ratio-9-16> :first-child {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.striped-rows td {
  font-size: 0.95em;
}

.striped-rows tr:hover td {
  background: rgba(0, 21, 41, 0.2) !important;
}

.striped-rows tr:not(:hover) td {
  background-color: white;
}

.striped-rows tr:nth-child(2n):not(:hover) td {
  background-color: #fbfbfb !important;
}

.striped-rows thead th {
  background-color: white;
}

.striped-rows tr td a {
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
}
